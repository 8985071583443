import React from 'react'
import moment from 'moment'
import PriceFormat from './shared/PriceFormat'
import Icon from './Icons'

function formatTimestamp(timestamp) {
  return moment(timestamp).format('MMMM DD, YYYY')// August 19, 2018
}

function OrderPageOrderDetailsField(props) {
  const {
    title, value, unit, children,
  } = props
  return (
    <div className='orderPage-orderDetails-detailField'>
      <div className='orderPage-orderDetails-detailField-title'>
        {title}
      </div>
      <div className='orderPage-orderDetails-detailField-value'>
        {/* either display children component or value prop */}
        {children || value}
        {' '}
        { unit && <span>{unit}</span> }
        {/* e.g. $57.50 / Membership  */}
        {/* e.g. $575.04  */}
      </div>
    </div>
  )
}

function OrderPageOrderDetails(props) {
  const { order } = props
  const invoicePaid = !!order.paidAt
  const invoiceDueDate = order.invoiceDue
  const invoicePaidDate = order.paidAt

  return (

    <div className='orderPage-orderDetails'>
      <div className='orderPage-orderDetails-title'>
        Order Details
      </div>
      <div className='orderPage-orderDetails-colContainer'>
        <div className='orderPage-orderDetails-col'>
          {order.plickersInvoice && (
            <OrderPageOrderDetailsField
              title='Plickers Invoice #'
              value={order.plickersInvoice}
            />
          )}
          <OrderPageOrderDetailsField
            title='Invoice Status'
            value={invoicePaid ? `Paid ${formatTimestamp(invoicePaidDate)}` : `Due ${formatTimestamp(invoiceDueDate)}`}
          />
          {order.invoiceLink && (
            <OrderPageOrderDetailsField
              title='View Invoice'
            >
              <a
                href={order.invoiceLink}
                target='_blank'
                rel='noreferrer'
                className='orderPage-orderDetails-invoiceLink'
              >
                <div>Open</div>
                <div className='orderPage-orderDetails-invoiceLink-iconContainer'>
                  <Icon name='open-link' />
                </div>
              </a>
            </OrderPageOrderDetailsField>
          )}
          {order.po && (
            <OrderPageOrderDetailsField
              title='Purchase Order #'
              value={order.po}
            />
          )}
        </div>

        <div className='orderPage-orderDetails-col'>
          <OrderPageOrderDetailsField
            title='Memberships Purchased'
            value={order.passQty}
            unit=' Annual'
          />
          <OrderPageOrderDetailsField
            title='Unit Price'
            unit='/ Membership'
          >
            <PriceFormat
              value={order.totalAmount / order.passQty / 100}
              currency={order.currency}
            />
          </OrderPageOrderDetailsField>
          <OrderPageOrderDetailsField
            title='Invoice Total'
          >
            <PriceFormat
              value={order.totalAmount / 100}
              currency={order.currency}
            />
          </OrderPageOrderDetailsField>
        </div>
      </div>
    </div>
  )
}

export default OrderPageOrderDetails
