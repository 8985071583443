import React from 'react'
import Paper from '@material-ui/core/Paper'
import { Link } from 'react-router-dom'
import Icon from '../Icons'
import HomePagePriceTierTable from './HomePagePriceTierTable'
import PriceCalculator from '../shared/PriceCalculator'

export default function HomePage({
  seatCount, setSeatCount, currency, setCurrency, discountRates,
}) {
  return (
    <div className='homePage marketing'>
      <div className='homePage-logo'>
        <Icon name='plickersForSchoolsLogo' />
      </div>
      <div className='homePage-titleContainer'>
        <h1 className='homePage-titleContainer-title1'>
          <span className='homePage-titleContainer-title1-plickers'>plickers&nbsp;</span>
          <span className='homePage-titleContainer-title1-pro'>pro</span>
        </h1>
        <h1 className='homePage-titleContainer-title2'>
          for your entire team
        </h1>
        <h3 className='homePage-titleContainer-description'>
          Get Plickers Pro for multiple teachers at once.
        </h3>
        <h3 className='homePage-titleContainer-description'>
          Save with volume discounts.
          Pay by check, bank transfer, or credit card.
        </h3>
      </div>
      <div className='homePage-priceTierTableContainer'>
        <HomePagePriceTierTable discountRates={discountRates} />
      </div>
      <div className='homePage-note'>
        Questions? Consult
        {' '}
        <a
          href='https://help.plickers.com/hc/en-us/articles/4406394121115-How-to-purchase-a-group-order-of-Plickers-Pro'
          target='_blank'
          rel='noreferrer'
        >
          our guide
        </a>
        {' '}
        or contact us at
        {' '}
        <a
          href='mailto:support@plickers.com'
          target='_blank'
          rel='noreferrer'
        >
          support@plickers.com
        </a>
      </div>
      <hr className='homePage-divider' />
      <div className='homePage-priceCalculatorContainer'>
        <h2 className='homePage-priceCalculatorContainer-title'>Price Calculator</h2>
        <Paper className='homePage-priceCalculatorContainer-section' elevation={3}>
          <PriceCalculator
            seatCount={seatCount}
            setSeatCount={setSeatCount}
            currency={currency}
            setCurrency={setCurrency}
            discountRates={discountRates}
          />
        </Paper>
      </div>
      <div className='homePage-actionContainer'>
        <div className='homePage-actionContainer-purchaseLinkContainer'>
          <Link to='/purchase'>
            <button
              type='button'
              label='purchase'
              className='btn btn--large btn--blue homePage-purchaseButton'
            >
              Purchase
            </button>
          </Link>
        </div>
        <Link to='/quote'>
          <div className='homePage-actionContainer-quoteLink'>
            Request a quote
          </div>
        </Link>
      </div>
    </div>
  )
}
