import React from 'react'
import PriceCalculator from '../PriceCalculator'

export default function QuantityForm(props) {
  const {
    seatCount, setSeatCount, currency, setCurrency, discountRates,
  } = props
  return (
    <div className='orderFormQuantityFormContainer'>
      <PriceCalculator
        seatCount={seatCount}
        setSeatCount={setSeatCount}
        currency={currency}
        setCurrency={setCurrency}
        discountRates={discountRates}
      />
    </div>
  )
}
