import React, { useState } from 'react'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import PurchaserInfoForm from './_PurchaserInfoForm'
import Review from './_Review'
import QuantityForm from './_QuantityForm'
import checkIfSeatCountValid from '../../../utils/checkIfSeatCountValid'

const theme = createTheme({
  palette: {
    primary: {
      main: '#167BF0', // use plickers blue as the main color
    },
  },
  typography: {
    fontFamily: 'Plickers Lato',
  },
})

function getStepContent({
  activeStep,
  seatCount,
  setSeatCount,
  currency,
  setCurrency,
  purchaser,
  setPurchaser,
  hasTaxId,
  setHasTaxId,
  taxIdObject,
  setTaxIdObject,
  discountRates,
  hasPo,
  setHasPo,
  poNumber,
  setPoNumber,
  poFile,
  setPoFile,
  config,
}) {
  switch (activeStep) {
    case 0:
      return (
        <QuantityForm
          seatCount={seatCount}
          setSeatCount={setSeatCount}
          currency={currency}
          setCurrency={setCurrency}
          discountRates={discountRates}
        />
      )
    case 1:
      return (
        <PurchaserInfoForm
          purchaser={purchaser}
          setPurchaser={setPurchaser}
          hasTaxId={hasTaxId}
          setHasTaxId={setHasTaxId}
          taxIdObject={taxIdObject}
          setTaxIdObject={setTaxIdObject}
          hasPo={hasPo}
          setHasPo={setHasPo}
          poNumber={poNumber}
          setPoNumber={setPoNumber}
          poFile={poFile}
          setPoFile={setPoFile}
          showPoInput={config.showPoInput}
        />
      )
    case 2:
      return (
        <Review
          seatCount={seatCount}
          currency={currency}
          purchaser={purchaser}
          hasTaxId={hasTaxId}
          taxIdObject={taxIdObject}
          discountRates={discountRates}
          hasPo={hasPo}
          poNumber={poNumber}
          poFile={poFile}
          reviewDescription={config.reviewDescription}
        />
      )
    default:
      throw new Error('Unknown step')
  }
}

function getInitialPurchaser() {
  return {
    name: '',
    organizationName: '',
    email: '',
    phone: '',
    addressObject: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
    },
  }
}

function Confirmation({ successMessageTitle, successMessageText }) {
  return (
    <div className='orderForm-confirmation'>
      <h2>{successMessageTitle}</h2>
      <h3>
        {successMessageText}
      </h3>
    </div>
  )
}

export default function OrderForm(props) {
  const {
    seatCount, setSeatCount, currency, setCurrency, discountRates, config,
  } = props
  const steps = ['Quantity', 'Purchaser info', `Review your ${config.itemName}`]

  const [activeStep, setActiveStep] = useState(0)
  const [purchaser, setPurchaser] = useState(getInitialPurchaser())
  const [hasTaxId, setHasTaxId] = useState(false)
  const [taxIdObject, setTaxIdObject] = useState({ type: '', value: '' })
  const [hasPo, setHasPo] = useState(false)
  const [poNumber, setPoNumber] = useState('')
  const [poFile, setPoFile] = useState(null)
  const [paddedItemNumber, setPaddedItemNumber] = useState('')
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleNext = () => {
    setActiveStep(activeStep + 1)
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  const isPurchaserInfoFilled = () => {
    const {
      name,
      organizationName,
      email,
      addressObject,
    } = purchaser
    const {
      line1, city, postalCode, country,
    } = addressObject
    const isPurchaserDetailsFilled = name &&
    organizationName &&
    email
    const isAddressFilled = line1 &&
      city &&
      postalCode &&
      country
    const isRequitedTaxInfoFilled = !hasTaxId || (taxIdObject.type && taxIdObject.value)
    const isRequiredPoInfoFilled = !hasPo || (poNumber && poFile)
    return isPurchaserDetailsFilled &&
      isAddressFilled &&
      isRequitedTaxInfoFilled &&
      isRequiredPoInfoFilled
  }

  const switchStep = (label) => {
    const index = steps.indexOf(label)
    if (index === 2 && !isPurchaserInfoFilled()) {
      return
    }
    setActiveStep(index)
  }

  const placeOrder = async () => {
    setSubmitLoading(true)
    setErrorMessage('')

    const orderReqBody = {
      passQty: seatCount,
      currency,
      purchaser: { ...purchaser },
      po: hasPo ? poNumber : '',
    }

    if (hasTaxId && taxIdObject.type && taxIdObject.value) {
      orderReqBody.purchaser.taxIdData = [taxIdObject]
    }

    try {
      const item = await config.create(orderReqBody, hasPo && poFile)
      setSubmitLoading(false)
      setPaddedItemNumber(item.paddedOrderNumber || item.paddedQuoteNumber)
      handleNext()
    } catch (e) {
      setSubmitLoading(false)
      setErrorMessage(e.message)
    }
  }

  return (
    <div className='orderForm'>
      <CssBaseline />
      <ThemeProvider theme={theme}>

        <Paper className='orderForm-purchasePaper'>
          <Stepper activeStep={activeStep} className='orderForm-purchasePaper-stepper'>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel onClick={() => switchStep(label)}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <Confirmation
              successMessageTitle={config.successMessageTitle}
              successMessageText={config.getSuccessMessageText(paddedItemNumber)}
            />
          ) : (
            <React.Fragment>
              {getStepContent({
                activeStep,
                seatCount,
                setSeatCount,
                currency,
                setCurrency,
                purchaser,
                setPurchaser,
                hasTaxId,
                setHasTaxId,
                taxIdObject,
                setTaxIdObject,
                discountRates,
                hasPo,
                setHasPo,
                poNumber,
                setPoNumber,
                poFile,
                setPoFile,
                config,
              })}
              <div className='orderForm-purchasePaper-buttons'>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} className='orderForm-purchasePaper-buttons-backButton'>
                    Back
                  </Button>
                )}
                {!submitLoading && (
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={activeStep === steps.length - 1 ? placeOrder : handleNext}
                    disabled={
                      (activeStep === 0 && !checkIfSeatCountValid(seatCount)) ||
                      (activeStep === 1 && !isPurchaserInfoFilled())
                    }
                    className='orderForm-purchasePaper-buttons-actionButton'
                  >
                    {activeStep === steps.length - 1 ? config.submitButtonLabel : 'Next'}
                  </Button>
                )}
                {submitLoading && (
                  <Button
                    variant='contained'
                    color='primary'
                    className='orderForm-purchasePaper-buttons-loadingButton'
                  >
                    <div className='button-loadingSpinnerContainer'>
                      <div className='button-loadingSpinner' />
                    </div>
                  </Button>
                )}
              </div>
              {errorMessage && (
                <p className='orderForm-errorMessage'>{errorMessage}</p>
              )}
            </React.Fragment>
          )}
        </Paper>
      </ThemeProvider>
    </div>
  )
}
