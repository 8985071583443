import React, { Component } from 'react'


//format orderNumber to 5 digits e.g. 60-->00060
function formatOrderNumber(orderNumber){
	let str = '' + orderNumber
	while (str.length < 5) {
		str = '0' + str
	}
	return str
}

class OrderPageHeader extends Component {

	render() {	
		return (				
			<div className='orderPage-header'>					
				<div className='orderPage-header-orderNumber'>
					Order #{formatOrderNumber(this.props.orderNumber)}
				</div>

				<div className='orderPage-header-licenseKey'>
					<div className='orderPage-header-licenseKey-label'>
						License Key
					</div>
					<div className='orderPage-header-licenseKey-key'>
						{this.props.licenseKey}
					</div>					 
				</div>

				<div className='orderPage-header-organization'>
					{this.props.organization}
				</div>
				<div className='orderPage-header-orderStatusBadgeContainer'>
					<div className={'orderPage-header-orderStatusBadge' + (this.props.isActive ? ' orderPage-header-orderStatusBadge--active' : ' orderPage-header-orderStatusBadge--preActive')}>
						{this.props.isActive ? 'Active' : 'Ready to Activate' }
					</div>				
					{this.props.isActive && this.props.expiryDate &&
						<div className='orderPage-header-orderStatusExpiry'>
							Valid through {this.props.expiryDate}
						</div>
					}
				</div>
			</div>
					
		)
	}
}

export default OrderPageHeader

