import React, { Component } from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import Icon from './Icons'


class OrderPageClaimMembershipLink extends Component {
	constructor(){
		super()	
		this.state={
			copied: false
		}
	}

	render() {	
		const {licenseKey} = this.props
		return (	
			<React.Fragment>			
				{licenseKey &&
					<CopyToClipboard text={`${this.props.magicLinkURLPrefix}${licenseKey}`} onCopy={() => this.setState({copied: true})}>
						<div onMouseLeave={()=>{this.setState({copied:false})}} className='orderPage-claimMembershipLink'>
							<div className='orderPage-claimMembershipLink-iconContainer'>			
								<Icon name='link' />
							</div>

							<div className='orderPage-claimMembershipLink-h1'>
								Claim Membership Link
							</div>
							
							<div className='orderPage-claimMembershipLink-URL'>
								{this.props.magicLinkURLPrefix}{licenseKey}
							</div>

							{this.state.copied &&
								<div className='orderPage-claimMembershipLink-copyToClipboardLabel orderPage-claimMembershipLink-copyToClipboardLabel--copied'>
									Copied to Clipboard
								</div>
							}

							{!this.state.copied &&
								<div className='orderPage-claimMembershipLink-copyToClipboardLabel orderPage-claimMembershipLink-copyToClipboardLabel--copy'>
									Copy to Clipboard
								</div>
							}
						</div>
					</CopyToClipboard>
				}				
			</React.Fragment>
					
		)
	}
}

export default OrderPageClaimMembershipLink

