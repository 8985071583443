import React from 'react'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import countries from '../../../utils/countries'
import { taxTypeEnums, getTaxTypeDisplayName } from '../../../utils/taxIdType'
import PoInputSection from '../PoInputSection'

export default function PurchaserInfoForm(props) {
  const {
    purchaser, setPurchaser,
    hasTaxId, setHasTaxId,
    taxIdObject, setTaxIdObject,
    hasPo, setHasPo,
    poNumber, setPoNumber,
    poFile, setPoFile,
    showPoInput,
  } = props

  function updatePurchaser(event, fieldName) {
    const newValue = event.target.value
    let newPurchaser
    if (fieldName.includes('addressObject')) {
      const addressFieldName = fieldName.split('.')[1]
      newPurchaser = {
        ...purchaser,
        addressObject: {
          ...purchaser.addressObject,
          [addressFieldName]: newValue,
        },
      }
    } else {
      newPurchaser = {
        ...purchaser,
        [fieldName]: newValue,
      }
    }
    setPurchaser(newPurchaser)
  }

  function updateTaxInfo(event, fieldName) {
    const newValue = event.target.value
    setTaxIdObject({
      ...taxIdObject,
      [fieldName]: newValue,
    })
  }

  return (
    <div className='orderFormPurchaserInfoFormContainer'>
      <div className='orderFormPurchaserInfoFormContainer-requiredInfo'>
        * required information
      </div>
      <div
        className='orderFormPurchaserInfoForm-sectionTitle orderFormPurchaserInfoForm-sectionTitle--first'
      >
        Purchaser details
      </div>
      <TextField
        required
        id='name'
        name='name'
        label='name'
        fullWidth
        value={purchaser.name}
        onChange={(event) => updatePurchaser(event, 'name')}
      />
      <TextField
        required
        id='organizationName'
        name='organizationName'
        label='organization'
        fullWidth
        value={purchaser.organizationName}
        onChange={(event) => updatePurchaser(event, 'organizationName')}
      />
      <TextField
        required
        id='email'
        name='email'
        label='email'
        fullWidth
        value={purchaser.email}
        onChange={(event) => updatePurchaser(event, 'email')}
      />
      <TextField
        id='phone'
        name='phone'
        label='phone'
        fullWidth
        // Stripe's phone field character limit: 20
        inputProps={{ maxLength: 20 }}
        value={purchaser.phone}
        onChange={(event) => updatePurchaser(event, 'phone')}
      />
      <div
        className='orderFormPurchaserInfoForm-sectionTitle'
      >
        Billing address
      </div>
      <TextField
        required
        id='address1'
        name='address1'
        label='address line 1'
        fullWidth
        value={purchaser.addressObject.line1}
        className='orderFormPurchaseInfo-input'
        onChange={(event) => updatePurchaser(event, 'addressObject.line1', true)}
      />
      <TextField
        id='address2'
        name='address2'
        label='address line 2'
        fullWidth
        value={purchaser.addressObject.line2}
        onChange={(event) => updatePurchaser(event, 'addressObject.line2')}
      />
      <TextField
        required
        id='city'
        name='city'
        label='city'
        value={purchaser.addressObject.city}
        className='orderFormPurchaserInfoForm-textField--half'
        onChange={(event) => updatePurchaser(event, 'addressObject.city')}
      />
      <TextField
        id='state'
        name='state'
        label='state'
        value={purchaser.addressObject.state}
        className='orderFormPurchaserInfoForm-textField--half'
        onChange={(event) => updatePurchaser(event, 'addressObject.state')}
      />
      <TextField
        required
        id='country'
        name='country'
        label='country'
        className='orderFormPurchaserInfoForm-textField--half'
        value={purchaser.addressObject.country}
        defaultValue=''
        onChange={(event) => updatePurchaser(event, 'addressObject.country')}
        select
      >
        {countries.map((option) => (
          <MenuItem key={option.code} value={option.code}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        required
        id='zip'
        name='zip'
        label='zip / postal code'
        className='orderFormPurchaserInfoForm-textField--half'
        value={purchaser.addressObject.postalCode}
        onChange={(event) => updatePurchaser(event, 'addressObject.postalCode')}
      />
      <div className='orderFormPurchaserInfoForm-sectionTitle'>
        Tax ID (optional)
      </div>
      <div className='orderFormPurchaserInfoForm-taxIdInputSection'>
        <FormControlLabel
          control={(
            <Checkbox
              color='primary'
              checked={hasTaxId}
              onClick={() => setHasTaxId(!hasTaxId)}
            />
          )}
          label='I have a tax ID'
        />
        {hasTaxId && (
          <div className='orderFormPurchaserInfoForm-taxIdInputSection-textInputContainer'>
            <TextField
              id='taxIdType'
              name='taxIdType'
              label='tax type'
              className='orderFormPurchaserInfoForm-textField--half'
              value={taxIdObject.type}
              defaultValue=''
              onChange={(event) => updateTaxInfo(event, 'type')}
              select
            >
              {taxTypeEnums.map((taxType) => (
                <MenuItem key={taxType} value={taxType}>
                  {getTaxTypeDisplayName(taxType, true)}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id='taxIdValue'
              name='taxIdValue'
              label='tax ID'
              className='orderFormPurchaserInfoForm-textField--half'
              value={taxIdObject.value}
              onChange={(event) => updateTaxInfo(event, 'value')}
            />
          </div>
        )}
      </div>
      {showPoInput && (
        <React.Fragment>
          <div className='orderFormPurchaserInfoForm-sectionTitle'>
            Purchase order (optional)
          </div>
          <PoInputSection
            hasPo={hasPo}
            setHasPo={setHasPo}
            poNumber={poNumber}
            setPoNumber={setPoNumber}
            poFile={poFile}
            setPoFile={setPoFile}
          />
        </React.Fragment>
      )}
    </div>
  )
}
