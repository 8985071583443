import React, { Component } from 'react'
import Icon from './Icons'

class OrderPageTeacherInstructions extends Component {

	constructor(){
		super()		
		this.state={
			showEmailInstructions: false	//for toggle hide and show instructions		
		}
	}

	render() {	
		const {showEmailInstructions} = this.state		
		return (	
			<div className='orderPage-teacherInstructions'>				
				{!this.props.isActive &&
					<React.Fragment>			
						<div className='orderPage-info-iconContainer'>
							<Icon name='message' />
						</div>

						<div className='orderPage-teacherInstructions-h1'>
							Instructions to send to teachers to claim their membership:
						</div>
					</React.Fragment>
				}
				{this.props.isActive &&
					<React.Fragment>												
						{!showEmailInstructions &&
							<div className='orderPage-teacherInstructions-showInstructionsButton orderPage-teacherInstructions-showInstructionsButton--show' onClick={()=>{this.setState({showEmailInstructions: true})}}>
								Show instructions to send to teachers to claim their membership
							</div>
						}
						{showEmailInstructions &&
							<div className='orderPage-teacherInstructions-showInstructionsButton orderPage-teacherInstructions-showInstructionsButton--hide' onClick={()=>{this.setState({showEmailInstructions: false})}}>
								Hide instructions
							</div>
						}
					</React.Fragment>
				}
				{(!this.props.isActive || (this.props.isActive && showEmailInstructions)) &&
					<div className='orderPage-teacherInstructions-instructionsText'>
						We have a Plickers for Schools license!<br/><br/>
						You can now activate Plickers Pro on your Plickers Account, giving you Shared Packs, no Set Limits, unlimited Scoresheet, and more.<br/><br/>
						To join, click this link: {this.props.magicLinkURLPrefix}{this.props.licenseKey} and follow the steps.<br/><br/>					
						You can also join by entering the license key (<span className='orderPage-teacherInstructions-instructionsText-licenseKey'>{this.props.licenseKey}</span>) in your Plickers account page.<br/><br/>
					</div>											
				}
			</div>					
		)
	}
}

export default OrderPageTeacherInstructions

