import React from 'react'
import AmountDetails from '../AmountDetails'
import PurchaserSummary from '../PurchaserSummary'
import TaxSummary from '../TaxSummary'

export default function Review(props) {
  const {
    seatCount,
    currency,
    purchaser,
    hasTaxId,
    taxIdObject,
    discountRates,
    hasPo,
    poNumber,
    poFile,
    reviewDescription,
  } = props

  return (
    <div className='orderFormReviewContainer'>
      <div className='orderFormReviewContainer-description'>
        {reviewDescription}
      </div>
      <div className='orderFormReviewContainer-purchaser'>
        <h2>Purchaser</h2>
        <PurchaserSummary
          purchaser={purchaser}
        />
      </div>
      {hasTaxId && (
        <React.Fragment>
          <h2>Tax ID</h2>
          <TaxSummary taxIdObject={taxIdObject} />
        </React.Fragment>
      )}
      {hasPo && (
        <React.Fragment>
          <h2>Purchase order</h2>
          <div className='orderFormReviewContainer-purchaseOrder'>
            <div className='orderFormReviewContainer-purchaseOrder-poNumber'>{poNumber}</div>
            <div className='orderFormReviewContainer-purchaseOrder-poFileName'>
              {`(${poFile.name})`}
            </div>
          </div>
        </React.Fragment>
      )}
      <div className='orderFormReviewContainer-orderSummary'>
        <h2>Summary</h2>
        <AmountDetails
          seatCount={seatCount}
          currency={currency}
          discountRates={discountRates}
        />
      </div>
    </div>
  )
}
