import React from 'react'
import { getTaxTypeDisplayName } from '../../utils/taxIdType'

export default function TaxSummary(props) {
  const {
    taxIdObject,
  } = props
  return (
    <div className='taxSummaryContainer'>
      {(taxIdObject.type && taxIdObject.value) ?
        (<React.Fragment>
          <div>{getTaxTypeDisplayName(taxIdObject.type)}</div>
          <div>{taxIdObject.value}</div>
        </React.Fragment>) :
        '-'}
    </div>
  )
}
