import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

function getAddressString(addressObject) {
  const {
    line1, line2, city, state, country, postalCode,
  } = addressObject
  return `${[line1, line2, city].join(' ')}, ${[state, country, postalCode].join(' ')}`
}

export default function PurchaserSummary(props) {
  const { purchaser } = props
  return (
    <div className='purchaserSummaryContainer'>
      <Table className='purchaserSummaryContainer-table'>
        <TableBody>
          <TableRow>
            <TableCell className='purchaserSummaryContainer-table-title'>name</TableCell>
            <TableCell>{purchaser.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='purchaserSummaryContainer-table-title'>organization</TableCell>
            <TableCell>{purchaser.organizationName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='purchaserSummaryContainer-table-title'>email</TableCell>
            <TableCell>{purchaser.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='purchaserSummaryContainer-table-title'>phone</TableCell>
            <TableCell>{purchaser.phone || '-'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='purchaserSummaryContainer-table-title'>billing address</TableCell>
            <TableCell>{getAddressString(purchaser.addressObject)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}
