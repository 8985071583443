import React, { Component } from 'react'
import OrderPageNavbar from './OrderPageNavbar'
import OrderPageHeader from './OrderPageHeader'
import OrderPageInfo from './OrderPageInfo'
import OrderPageClaimMembershipLink from './OrderPageClaimMembershipLink'
import OrderPageTeacherInstructions from './OrderPageTeacherInstructions'
import OrderPageSeatsTable from './OrderPageSeatsTable'
import OrderPageOrderDetails from './OrderPageOrderDetails'
import moment from 'moment'
import RemoveUserModal from './RemoveUserModal'

function formatTimestamp(timestamp){
	return moment(timestamp).format('MMMM DD, YYYY')
}

class OrderPage extends Component {
	
	constructor(){
		super()	
		this.confirmRemoveUser=this.confirmRemoveUser.bind(this)	
		this.removeUserFromPass=this.removeUserFromPass.bind(this)	
		this.state={
			isActive: true,
			showConfirmRemoveUserModal:false,
			passToRemove:null,
			submitLoading:false //true while user is being removed from pass (used to show loading spinner)
		}
	}

	confirmRemoveUser(pass){ //show confirm modal
		this.setState({passToRemove:pass,showConfirmRemoveUserModal:true})
	}

	removeUserFromPass(){
		this.setState({submitLoading:true})
		this.props.removeUserFromPass(this.state.passToRemove.id).then(()=>{
			this.setState({showConfirmRemoveUserModal:false,passToRemove:null,submitLoading:false})
		})
	}

	render() {
		const {order}=this.props
		let isActive=false 
		if(order.activatedAt){ //if order has activatedAt timestamp isActive if true
			isActive=true
		}
		const licenseKey = order.licenseKey
		const magicLinkURLPrefix = process.env.REACT_APP_ORDER_LINK_PREFIX
		const organization = order.purchaser.organizationName
		return (	
			<div className={'orderPage ' + (isActive ? 'orderPage--active' : 'orderPage--inactive')}>
				{this.state.showConfirmRemoveUserModal &&
					<RemoveUserModal 
						submitLoading={this.state.submitLoading} 
						pass={this.state.passToRemove} 
						removeUserFromPass={this.removeUserFromPass}
						cancel={()=>{this.setState({showConfirmRemoveUserModal:false,passToRemove:null})}}
					/>
				}
				<OrderPageNavbar />
				<OrderPageHeader 
					isActive={isActive}
					orderNumber={order.orderNumber}
					organization={organization}
					licenseKey={licenseKey}
					expiryDate={order.extendedEndDate?formatTimestamp(order.extendedEndDate):formatTimestamp(order.endDate)}
				/>													
				<OrderPageInfo
					isActive={isActive}
					licenseKey={licenseKey}
					magicLinkURLPrefix={magicLinkURLPrefix}
				/>				
				<OrderPageClaimMembershipLink 
					licenseKey={licenseKey}
					magicLinkURLPrefix={magicLinkURLPrefix}					
					isActive={isActive}
				/>
				<OrderPageTeacherInstructions
					isActive={isActive}
					licenseKey={licenseKey}
					magicLinkURLPrefix={magicLinkURLPrefix}
				/>				
				{isActive &&
					<OrderPageSeatsTable 
					order={order} 
					passes={this.props.passes}
					confirmRemoveUser={this.confirmRemoveUser}
					sortedBy={this.props.sortedBy}
					sortOrder={this.props.sortOrder}
					changePassSortOrder={this.props.changePassSortOrder}
					/>
				}

				<OrderPageOrderDetails order={order} />
				<div className='orderPage-bottomPadding' />
			</div>	
		)
	}
}

export default OrderPage

