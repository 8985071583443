import api from '../api'

export function fetchQuote(quoteId, token) {
  return (dispatch) => api.fetch(`/quotes/${quoteId}?token=${token}`)
    .then((response) => {
      dispatch({ type: 'FETCH_QUOTE_SUCCESS', response })
      return response
    })
    .catch((error) => error)
}
