import React, { Component } from 'react'
import Icon from './Icons'


class OrderPageNavBar extends Component {
	render() {	
		return (		
			<div className='orderPage-navBar'>

				<div className='orderPage-navBar-logo'>
					<Icon name='plickersForSchoolsLogo' />
				</div>

				<div className='orderPage-navBar-support'>
					<div className='orderPage-navBar-support-label'>
						Questions?
					</div>
					<div className='orderPage-navBar-support-email'>
						help@plickers.com
					</div>  
				</div>
				
			</div>
		)
	}
}

export default OrderPageNavBar

