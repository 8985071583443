import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import App from './containers/AppContainer'
import './styles/css/App.css'
import store from './store'
import { listDiscountRates } from './actions/discountRates'

// Dispatch the listDiscountRates() before the root component renders
store.dispatch(listDiscountRates())

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
)
