import api from '../api'

// http://plickers-api-documentation-pgjd3e8jb7.s3-website-us-east-1.amazonaws.com/#list-order-discount-rates
export function listDiscountRates() {
  return (dispatch) => api.fetch('/orders/discount-rates')
    .then((response) => {
      dispatch({ type: 'FETCH_DISCOUNT_RATES_SUCCESS', response })
      return response
    })
    .catch((error) => error)
}
