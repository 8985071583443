import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '../Icons'
import OrderForm from '../shared/orderForm/OrderForm'

export default function PurchasePage(props) {
  const {
    seatCount, setSeatCount, currency, setCurrency, discountRates, config,
  } = props
  const { title } = config
  return (
    <div className='purchasePage marketing'>
      <Link to='/'>
        <div className='purchasePage-logo'>
          <Icon name='plickersForSchoolsLogo' />
        </div>
      </Link>
      <h1 className='purchasePage-title'>{title}</h1>
      <div className='purchasePage-orderFormContainer'>
        <OrderForm
          seatCount={seatCount}
          setSeatCount={setSeatCount}
          currency={currency}
          setCurrency={setCurrency}
          discountRates={discountRates}
          config={config}
        />
      </div>
    </div>
  )
}
