export const getPriceTierRows = (discountRates) => {
  // discountRates example
  // const discountRates = [
  //   { minQty: 0, discountPercent: 0 },
  //   { minQty: 5, discountPercent: 5 },
  //   { minQty: 10, discountPercent: 10 },
  //   { minQty: 20, discountPercent: 15 },
  //   { minQty: 50, discountPercent: 20 },
  //   { minQty: 100, discountPercent: 25 },
  //   { minQty: 1000, discountPercent: 30 },
  // ]

  // create priceTierRows that looks like this
  // const rows = [
  //   createData('5 - 9', 5),
  //   createData('10 - 19', 10),
  //   createData('20 - 49', 15),
  //   createData('50 - 99', 20),
  //   createData('100 - 999', 25),
  //   createData('1000+', 30),
  // ]

  let fromQty = 1
  let discountPercent = 0
  const tableRows = []
  discountRates.forEach((discountRate) => {
    // add tier that has discount
    if (discountPercent > 0) {
      const untilQty = discountRate.minQty - 1
      tableRows.push({
        seatCount: `${fromQty} - ${untilQty}`,
        discountPercent,
      })
    }
    // set minQty and discountPercent for next round
    fromQty = discountRate.minQty
    discountPercent = discountRate.discountPercent
  })
  // add the max tier
  tableRows.push({
    seatCount: `${fromQty}+`,
    discountPercent,
  })
  return tableRows
}

export const getDiscountPercent = (discountRates, seatCount) => {
  let discountPercent = 0
  discountRates.forEach((tier) => {
    if (seatCount >= tier.minQty) {
      discountPercent = tier.discountPercent
    }
  })
  return discountPercent
}
