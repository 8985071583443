import React from 'react'
import NumberFormat from 'react-number-format'
import { getCurrencySymbol } from '../../utils/currency'

export default function PriceFormat({ value = 0, currency }) {
  return (
    <NumberFormat
      value={(value).toFixed(2)}
      displayType='text'
      thousandSeparator
      prefix={getCurrencySymbol(currency)}
    />
  )
}
