import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import Icon from '../Icons'

function getFileSizeString(size) {
  return `${Math.round(size / 1000).toLocaleString()} k`
}

export default function PoInputSection(props) {
  const {
    hasPo, setHasPo,
    poNumber, setPoNumber,
    poFile, setPoFile,
  } = props

  const fileInput = React.useRef()
  function selectPoFile() {
    fileInput.current.click()
  }

  function onFileChange(e) {
    e.stopPropagation()
    e.preventDefault()
    setPoFile(e.target.files[0])
  }

  function removePoFile() {
    setPoFile(null)
  }

  function handleKeyDownOnTrash(e) {
    if (e.keyCode === 13) {
      removePoFile()
    }
  }

  return (
    <div className='poInputSection'>
      <div className='poInputSection-poCheckbox'>
        <FormControlLabel
          control={(
            <Checkbox
              color='primary'
              checked={hasPo}
              onClick={() => setHasPo(!hasPo)}
            />
          )}
          label='I have a purchase order (PO)'
        />
      </div>
      {hasPo && (
        <React.Fragment>
          <TextField
            required
            id='poNumber'
            name='poNumber'
            label='po number'
            className='poInputSection-textField--half'
            value={poNumber}
            onChange={(event) => setPoNumber(event.target.value)}
          />

          {!poFile && (
            <React.Fragment>
              <Button
                variant='contained'
                color='primary'
                onClick={selectPoFile}
              >
                upload
              </Button>
              <input
                ref={fileInput}
                type='file'
                style={{ display: 'none' }}
                onChange={onFileChange}
                accept='.pdf'
              />
            </React.Fragment>
          )}
          {poFile && (
            <div className='poInputSection-poFileInfo'>
              <div className='poInputSection-poFileInfo-title'>{`${poFile.name} (${getFileSizeString(poFile.size)})`}</div>
              <div
                className='poInputSection-poFileInfo-iconHolder'
                role='button'
                tabIndex={0}
                onClick={removePoFile}
                onKeyDown={handleKeyDownOnTrash}
              >
                <Icon name='trash' />
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  )
}
