const config = {
  usd: { symbol: '$', unitPrice: 71.88 },
  gbp: { symbol: '£', unitPrice: 65 },
  eur: { symbol: '€', unitPrice: 75 },
  cad: { symbol: 'C$', unitPrice: 100 },
  aud: { symbol: 'A$', unitPrice: 115 },
}

export function getCurrencyEnums() {
  return Object.keys(config)
}

export function getUnitPrice(currency) {
  return config[currency].unitPrice
}

export function getCurrencySymbol(currency) {
  return config[currency].symbol
}
