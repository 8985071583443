import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Icon from '../Icons'
import PoInputSection from '../shared/PoInputSection'
import PurchaserSummary from '../shared/PurchaserSummary'
import TaxSummary from '../shared/TaxSummary'
import AmountDetails from '../shared/AmountDetails'
import {
  createOrderWithPoFileIfAny,
  getOrderRequestReviewDescription,
} from '../../utils/orderQuoteHelper'

const theme = createTheme({
  palette: {
    primary: {
      main: '#167BF0', // use plickers blue as the main color
    },
  },
  typography: {
    fontFamily: 'Plickers Lato',
  },
})

export default function PurchaseFromQuotePage(props) {
  const { quote } = props
  const {
    passQty: seatCount,
    currency,
    purchaser,
    unitPrice,
    discountPercent,
  } = quote
  const taxIdObject = purchaser.taxIdData[0]

  const [hasPo, setHasPo] = useState(false)
  const [poNumber, setPoNumber] = useState('')
  const [poFile, setPoFile] = useState(null)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [order, setOrder] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')

  const createOrder = async function createOrder() {
    setSubmitLoading(true)
    setErrorMessage('')

    try {
      const orderReqBody = { po: hasPo ? poNumber : '' }
      const order = await createOrderWithPoFileIfAny(orderReqBody, hasPo && poFile, quote.id)
      setSubmitLoading(false)
      setOrder(order)
    } catch (e) {
      setSubmitLoading(false)
      setErrorMessage(e.message)
    }
  }

  return (
    <div className='purchaseFromQuotePage marketing'>
      <Link to='/'>
        <div className='purchaseFromQuotePage-logo'>
          <Icon name='plickersForSchoolsLogo' />
        </div>
      </Link>
      <ThemeProvider theme={theme}>

        <Paper className='purchaseFromQuotePage-paper'>
          <div className='purchaseFromQuotePage-container'>
            <h1 className='purchaseFromQuotePage-title'>Purchase</h1>
            <div className='purchaseFromQuotePage-subtitle'>{`Quote #${quote.paddedQuoteNumber}`}</div>
            <div className='purchaseFromQuotePage-description'>
              {getOrderRequestReviewDescription()}
            </div>
            <div className='purchaseFromQuotePage-summaryContainer'>
              <div className='purchaseFromQuotePage-summaryContainer-purchaser'>
                <h2>Purchaser</h2>
                <PurchaserSummary
                  purchaser={purchaser}
                  taxIdObject={taxIdObject}
                />
              </div>
              {taxIdObject && (
                <div className='purchaseFromQuotePage-summaryContainer-taxId'>
                  <h2>Tax info</h2>
                  <TaxSummary
                    purchaser={purchaser}
                    taxIdObject={taxIdObject}
                  />
                </div>
              )}
              <div className='purchaseFromQuotePage-summaryContainer-po'>
                <h2>Purchase order</h2>
                <PoInputSection
                  hasPo={hasPo}
                  setHasPo={setHasPo}
                  poNumber={poNumber}
                  setPoNumber={setPoNumber}
                  poFile={poFile}
                  setPoFile={setPoFile}
                />
              </div>
              <div className='purchaseFromQuotePage-summaryContainer-orderSummery'>
                <h2>Summary</h2>
                <AmountDetails
                  seatCount={seatCount}
                  currency={currency}
                  forcePricing
                  unitPrice={unitPrice / 100}
                  discountPercent={discountPercent}
                />
              </div>
              <div className='purchaseFromQuotePage-actionContainer'>
                {!submitLoading && (
                  <Button
                    variant='contained'
                    color='primary'
                    size='large'
                    className='purchaseFromQuotePage-purchaseButton'
                    onClick={() => createOrder()}
                    disabled={!!order}
                  >
                    Purchase
                  </Button>
                )}
                {submitLoading && (
                  <Button
                    variant='contained'
                    color='primary'
                    className='purchaseFromQuotePage-loadingButton'
                  >
                    <div className='button-loadingSpinnerContainer'>
                      <div className='button-loadingSpinner' />
                    </div>
                  </Button>
                )}
                {order && (
                  <div className='purchaseFromQuotePage-successMessageContainer'>
                    <div>Thank you for your order.</div>
                    <div>
                      {`Your order number is #${order.orderNumber}.
                        Please check your email for your order confirmation.`}
                    </div>
                  </div>
                )}
                {errorMessage && (
                  <p className='purchaseFromQuotePage-errorMessage'>{errorMessage}</p>
                )}
              </div>
            </div>
          </div>
        </Paper>
      </ThemeProvider>
    </div>
  )
}
