import api from '../api'
import { getTaxTypeDisplayName } from './taxIdType'

async function uploadPo(poFile) {
  // generate signedUrl for po upload
  const { url, path } = await api.fetch('/orders/po-presigned-url')
  // upload file
  const formData = new FormData()
  formData.append('File', poFile)
  await fetch(url, {
    method: 'PUT',
    body: formData,
    headers: {
      'Content-Type': 'application/pdf',
      'Content-Disposition': 'inline',
    },
  })
  return path
}

function getUploadPoErrorMessage(e) {
  if (e.response) {
    const { status } = e.response
    return `failed to upload po file. please try again. error code: ${status}`
  }
  return 'Cannot send request. Please check internet connection.'
}

async function getCreateRequestErrorMessage(e, reqBody) {
  if (e.response) {
    const { response } = e
    const text = await response.text()
    if (text && text.includes('tax_id_invalid')) {
      const taxIdType = reqBody.purchaser.taxIdData[0].type
      return `Provided tax id value is invalid for ${getTaxTypeDisplayName(taxIdType)}`
    }
    const message = text ? ` - ${text}` : ''
    return `something went wrong. (code: ${response.status})${message}`
  }
  return 'Cannot send request. Please check internet connection.'
}

async function createQuote(quoteReqBody) {
  try {
    const quote = await api.post('/quotes', quoteReqBody)
    return quote
  } catch (e) {
    const errorMessage = await getCreateRequestErrorMessage(e, quoteReqBody)
    throw new Error(errorMessage)
  }
}

export async function createOrderWithPoFileIfAny(orderReqBody, poFile, quoteId) {
  const reqBody = { ...orderReqBody }

  if (poFile) {
    try {
      const path = await uploadPo(poFile)
      reqBody.poFilePath = path
    } catch (e) {
      const errorMessage = getUploadPoErrorMessage(e)
      // should not continue placing order
      throw new Error(errorMessage)
    }
  }

  try {
    const path = quoteId ? `/orders/from-quote?id=${quoteId}` : '/orders'
    const order = await api.post(path, reqBody)
    return order
  } catch (e) {
    const errorMessage = await getCreateRequestErrorMessage(e, reqBody)
    throw new Error(errorMessage)
  }
}

export function getOrderRequestReviewDescription() {
  return `Please review your details below.
  Once you place the order, we'll send you a confirmation email
  containing instructions for distributing memberships
  to your colleagues. You'll also receive a separate email containing a link to your invoice and payment page.`
}

function getQuoteSuccessMessageText(quoteNumber) {
  return `Your quote number is #${quoteNumber}. Please check your email for your quote.`
}

function getOrderSuccessMessageText(orderNumber) {
  return `Your order number is #${orderNumber}. Please check your email for your order confirmation.`
}

export function getConfig(itemName) {
  if (itemName === 'quote') {
    const reviewDescription = `Please review your details below.
    When the quote is ready, we'll send you an email
    containing the quote and a purchase link.`
    return {
      itemName: 'quote',
      title: 'Request a Quote',
      showPoInput: false,
      reviewDescription,
      submitButtonLabel: 'request quote',
      create: createQuote,
      successMessageTitle: 'Thank you for requesting a quote.',
      getSuccessMessageText: getQuoteSuccessMessageText,
    }
  }
  if (itemName === 'order') {
    return {
      itemName: 'order',
      title: 'Purchase',
      showPoInput: true,
      reviewDescription: getOrderRequestReviewDescription(),
      submitButtonLabel: 'place order',
      create: createOrderWithPoFileIfAny,
      successMessageTitle: 'Thank you for your order.',
      getSuccessMessageText: getOrderSuccessMessageText,
    }
  }
}
