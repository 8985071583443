import React from 'react'
import { connect } from 'react-redux'
import PurchasePage from '../components/purchase/PurchasePage'
import { updateSeatCount } from '../actions/seatCount'
import { updateCurrency } from '../actions/currency'
import { getConfig } from '../utils/orderQuoteHelper'

function QuoteContainer({
  seatCount, discountRates, currency, updateCurrency, updateSeatCount,
}) {
  return (
    <PurchasePage
      seatCount={seatCount}
      setSeatCount={updateSeatCount}
      currency={currency}
      setCurrency={updateCurrency}
      discountRates={discountRates}
      config={getConfig('quote')}
    />
  )
}

export default connect(
  (state) => ({
    seatCount: state.seatCount,
    currency: state.currency,
    discountRates: state.discountRates,
  }),
  { updateSeatCount, updateCurrency },
)(QuoteContainer)
