import React, { Component } from 'react'
import { connect } from 'react-redux'
import qs from 'qs' // querystring parsing and stringifying
import { fetchOrder } from '../actions/order'
import { fetchPasses, removeUserFromPass } from '../actions/passes'
import OrderPage from '../components/OrderPage'
import { sortPasses } from '../utils/sortPasses'

const getOrderParams = (location) => qs.parse(location.search.slice(1))

class OrderContainer extends Component {
  constructor(props) {
    super(props)
    this.removeUserFromPass = this.removeUserFromPass.bind(this)
    this.changePassSortOrder = this.changePassSortOrder.bind(this)

    let orderId
    let token
    const orderParams = getOrderParams(window.location) // get params from url
    if (orderParams) {
      orderId = props.id
      token = orderParams.token
    }
    this.state = {
      orderId,
      token,
      sortedBy: 'claimedDate', // default sort by
      sortOrder: 'ascending', // default sort order
      passes: props.passes,
    }
  }

  UNSAFE_componentWillMount() {
    this.props.fetchOrder(this.state.orderId, this.state.token)
    this.props.fetchPasses(this.state.orderId, this.state.token)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.passes !== nextProps.passes) {
      this.setState({ passes: nextProps.passes })
    }
  }

  changePassSortOrder(sortBy, sortOrder) {
    const sortedPasses = sortPasses(sortBy, sortOrder, this.props.passes)
    this.setState({ passes: sortedPasses, sortedBy: sortBy, sortOrder })
  }

  removeUserFromPass(passId) {
    const { token } = this.props.order
    const orderId = this.props.order.id
    return this.props.removeUserFromPass(passId, token, orderId)
  }

  render() {
    if (this.props.order) {
      return (
        <OrderPage
          order={this.props.order}
          passes={this.state.passes}
          removeUserFromPass={this.removeUserFromPass}
          sortedBy={this.state.sortedBy}
          sortOrder={this.state.sortOrder}
          changePassSortOrder={this.changePassSortOrder}
        />
      )
    } return null
  }
}

function mapStateToProps(state) {
  const sortedPasses = sortPasses('claimedDate', 'ascending', state.passes)
  return {
    passes: sortedPasses,
    order: state.order,
  }
}

export default connect(
  mapStateToProps,
  { fetchOrder, fetchPasses, removeUserFromPass },
)(OrderContainer)
