import api from '../api'

//http://plickers-api-documentation-pgjd3e8jb7.s3-website-us-east-1.amazonaws.com/#query-passes
export function fetchPasses(orderId,token) {
	return (dispatch) => api.fetch(`/passes?order=${orderId}&token=${token}&claimed=true`)
		.then((response) => {
			dispatch({ type: 'FETCH_PASSES_SUCCESS', response })
			return response
		})
		.catch((error) => {
			return error
		})
}

//http://plickers-api-documentation-pgjd3e8jb7.s3-website-us-east-1.amazonaws.com/#remove-user-from-pass
export function removeUserFromPass(passId,token,orderId) {
	return (dispatch) => api.put(`/passes/${passId}/remove-user?token=${token}`)
		.then((response) => {
			dispatch({ type: 'REMOVE_USER_FROM_PASS_SUCCESS', passId })
			return response
		})
		.catch((error) => {
			return error
		})
}

