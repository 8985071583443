import findIndex from 'lodash/findIndex'

export default function (state = [], action) {
	switch (action.type) {
		case 'FETCH_PASSES_SUCCESS':
			return action.response

		case 'REMOVE_USER_FROM_PASS_SUCCESS':
			const passId=action.passId
			const indexOfPass = findIndex(state, pass => {
				return pass.id === passId
			})
			if(indexOfPass!==-1){ //if found pass in state remove it	
				return [
					...state.slice(0,indexOfPass),
					...state.slice(indexOfPass + 1)
				]
			}else return state

		default:
			return state
	}
}

