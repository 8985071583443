import sortBy from 'lodash/sortBy'

export function sortPasses(sortType,sortOrder,passes) {
	let sorted = passes
	if (sortType === 'claimedDate') {
		if (sortOrder==='ascending') {
			sorted = sortBy(sorted, 'claimedAt').reverse()
		}else{
			sorted = sortBy(sorted, 'claimedAt')			
		}			
	}else if (sortType === 'firstName') {
		if (sortOrder==='ascending') {
			sorted = sortBy(sorted, [function(o) {
				return o.user.firstName.toString().toLowerCase()
			}])
		}else{
			sorted = sortBy(sorted, [function(o) {
				return o.user.firstName.toString().toLowerCase()
			}]).reverse()
		}
	}else if (sortType === 'lastName') {
		if (sortOrder==='ascending') {
			sorted = sortBy(sorted, [function(o) {
				return o.user.lastName.toString().toLowerCase()
			}])
		}else{
			sorted = sortBy(sorted, [function(o) {
				return o.user.lastName.toString().toLowerCase()
			}]).reverse()
		}
	}else if (sortType === 'email') {
		if (sortOrder==='ascending') {
			sorted = sortBy(sorted, [function(o) {
				return o.user.email.toString().toLowerCase()
			}])
		}else{
			sorted = sortBy(sorted, [function(o) {
				return o.user.email.toString().toLowerCase()
			}]).reverse()
		}
	}
	return sorted
}

