import React, { Component } from 'react'

//When user clicks remove user from order show confirmation modal asking them to confirm
//they want to remove user from order

class RemoveUserModal extends Component {

	constructor(props){
		super(props)
		this.handleBackgroundClick=this.handleBackgroundClick.bind(this)
		this.handleKeyDown=this.handleKeyDown.bind(this)
	}

	UNSAFE_componentWillMount() {
		document.body.style.overflowY='hidden' //prevent scrolling when modal is open
		window.addEventListener('keydown', this.handleKeyDown)
	}

	componentWillUnmount() {
		document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
		document.body.style.overflowY='overlay' // overlay is to prevent windows scrollbars interacting with pagewidth
		window.removeEventListener('keydown', this.handleKeyDown)
	}

	handleKeyDown(e){
		if(e.keyCode === 27){//escape key to close modal
			this.props.cancel()
		}
	}

	handleBackgroundClick(e){
		if (e.target === e.currentTarget) this.props.cancel() //close modal is click outside modal
	}

	render() {	
		const {pass,submitLoading}=this.props
		//When submitLoading=true show loading spinner on button
			return (			
				<div className='confirmationModalContainer' onClick={this.handleBackgroundClick}>
					<div className='confirmationModal'>
						<div className='confirmationModal-h1'>
							Are you sure you want to <b>remove {pass.user.firstName} {pass.user.lastName}</b> from your order?
						</div>
						<div className='confirmationModal-h2'>
							They will be removed immediately.
						</div>
						<div className='confirmationModal-h2'>
							{pass.user.firstName} will also lose access to Plickers Pro immediately.
						</div>							

						<div className='confirmationModal-buttonSet'>
							<button className='confirmationModal-confirmButton' onClick={this.props.removeUserFromPass}>								
								{!submitLoading &&
									<span>Yes, remove {pass.user.firstName}</span>
								}
								{submitLoading &&
									<div className='confirmationModal-confirmButton-submitLoadingSpinnerContainer'>
										<div className='confirmationModal-confirmButton-submitLoadingSpinner'/>
									</div>
								}													
							</button>
							<button className='confirmationModal-cancelButton' onClick={this.props.cancel}>
								Cancel
							</button>
						</div>					
					</div>					
				</div>					
			)
		}
	}

export default RemoveUserModal

