import { combineReducers } from 'redux'
import discountRates from './discountRates'
import currency from './currency'
import order from './order'
import passes from './passes'
import seatCount from './seatCount'
import quote from './quote'

const rootReducer = combineReducers({
  currency,
  discountRates,
  order,
  passes,
  seatCount,
  quote,
})

export default rootReducer
