import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { getDiscountPercent } from '../../utils/discountUtil'
import checkIfSeatCountValid from '../../utils/checkIfSeatCountValid'
import PriceFormat from './PriceFormat'
import { getUnitPrice } from '../../utils/currency'

export default function AmountDetails({
  seatCount,
  currency,
  forcePricing,
  discountRates,
  unitPrice,
  discountPercent,
}) {
  const isSeatCountValid = checkIfSeatCountValid(seatCount)
  let unitPriceValue
  let discountPercentValue
  let discountAmount
  let originalTotalAmount
  let totalAmountValue

  if (isSeatCountValid) {
    if (forcePricing) {
      unitPriceValue = unitPrice
      discountPercentValue = discountPercent
    } else {
      unitPriceValue = getUnitPrice(currency)
      discountPercentValue = getDiscountPercent(discountRates, seatCount)
    }
    discountAmount = (unitPriceValue * seatCount * discountPercentValue) / 100
    originalTotalAmount = unitPriceValue * seatCount
    totalAmountValue = originalTotalAmount - discountAmount
  }

  return (
    <TableContainer className='amountDetails-table'>
      <Table aria-label='simple table'>
        <TableHead className='amountDetails-table-header'>
          <TableRow>
            <TableCell align='left'> </TableCell>
            <TableCell align='left'>Qty</TableCell>
            <TableCell align='center'>Unit Price</TableCell>
            <TableCell align='right'>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className='amountDetails-table-body'>
          <TableRow>
            <TableCell align='left'>Plickers Pro 1 year membership</TableCell>
            <TableCell align='left'>{isSeatCountValid ? seatCount : '-'}</TableCell>
            <TableCell align='center'>
              <PriceFormat value={unitPriceValue} currency={currency} />
            </TableCell>
            <TableCell align='right'>
              {isSeatCountValid ? (<PriceFormat value={originalTotalAmount} currency={currency} />) : '-'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='left'>
              {`volume discount (${isSeatCountValid ? discountPercentValue : '-'}%)`}
            </TableCell>
            <TableCell align='left'> </TableCell>
            <TableCell align='left'> </TableCell>
            <TableCell align='right'>
              {isSeatCountValid ? (<PriceFormat value={-discountAmount} currency={currency} />) : '-'}
            </TableCell>
          </TableRow>
          <TableRow className='amountDetails-table-body-totalRow'>
            <TableCell align='left'>Total</TableCell>
            <TableCell align='left'> </TableCell>
            <TableCell align='left'> </TableCell>
            <TableCell align='right'>
              {isSeatCountValid ? (<PriceFormat value={totalAmountValue} currency={currency} />) : '-'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
