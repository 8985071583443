import React, { Component } from 'react'
import { connect } from 'react-redux'
import qs from 'qs'
import { fetchQuote } from '../actions/quote'
import PurchaseFromQuotePage from '../components/purchaseFromQuote/PurchaseFromQuotePage'

function getTokenParam(location) {
  const { token } = qs.parse(location.search.slice(1))
  return token
}

class PurchaseFromQuoteContainer extends Component {
  componentDidMount() {
    const { id, fetchQuote } = this.props
    const token = getTokenParam(window.location)
    fetchQuote(id, token)
  }

  render() {
    const { quote } = this.props
    if (quote) {
      return (
        <PurchaseFromQuotePage
          quote={quote}
        />
      )
    } return null
  }
}

export default connect(
  (state) => ({ quote: state.quote }),
  { fetchQuote },
)(PurchaseFromQuoteContainer)
