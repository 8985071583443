import React from 'react'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import AmountDetails from './AmountDetails'
import checkIfSeatCountValid from '../../utils/checkIfSeatCountValid'
import { getCurrencyEnums } from '../../utils/currency'

const currencyEnums = getCurrencyEnums()

const theme = createTheme({
  palette: {
    primary: {
      main: '#167BF0', // use plickers blue as the main color
    },
  },
  typography: {
    fontFamily: 'Plickers Lato',
  },
})

export default function PriceCalculator(props) {
  const {
    seatCount, setSeatCount, currency, setCurrency, discountRates,
  } = props
  function getHelperText(seatCountValue) {
    const isValid = checkIfSeatCountValid(seatCountValue)
    return isValid ? '' : 'Plickers for Schools requires at least 2 teachers'
  }

  function updateSeatCount(event) {
    const newSeatCountString = event.target.value
    let newSeatCount = parseInt(newSeatCountString, 10)
    // make sure the seat count is positive number
    // (not replacing the value with 2 or above here,
    //  so this can handle input of number staring from 1 e.g. 12)
    newSeatCount = Math.max(newSeatCount, 0)
    setSeatCount(newSeatCount)
  }

  function updateCurrency(event) {
    setCurrency(event.target.value)
  }

  return (
    <div className='priceCalculator'>
      <ThemeProvider theme={theme}>
        <Box
          justifyContent='space-between'
          display='flex'
          alignItems='center'
        >
          <TextField
            className='priceCalculator-seatCountInput'
            id='outlined-number'
            label='teachers'
            type='number'
            InputLabelProps={{
              shrink: true,
            }}
            variant='outlined'
            // avoid error when seatCount input is blank by setting this to an empty string
            value={Number.isNaN(seatCount) ? '' : seatCount}
            error={!checkIfSeatCountValid(seatCount)}
            helperText={getHelperText(seatCount)}
            onChange={(e) => updateSeatCount(e)}
            autoFocus
          />
          <Select
            className='priceCalculator-currencyInput'
            id='priceCalculator-currencyInput'
            value={currency}
            onChange={(e) => updateCurrency(e)}
            label='currency'
          >
            {currencyEnums.map((currencyValue) => (
              <MenuItem
                key={currencyValue}
                value={currencyValue}
                className='priceCalculator-currencyInput-option'
              >
                {currencyValue.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <AmountDetails
          seatCount={seatCount}
          currency={currency}
          discountRates={discountRates}
        />
      </ThemeProvider>
    </div>
  )
}
