import React from 'react'
import ReactGA from 'react-ga4'
import {
  BrowserRouter, Switch, Redirect, Route, useParams, useLocation,
} from 'react-router-dom'
import HomeContainer from './HomeContainer'
import OrderContainer from './OrderContainer'
import PurchaseContainer from './PurchaseContainer'
import QuoteContainer from './QuoteContainer'
import PurchaseFromQuoteContainer from './PurchaseFromQuoteContainer'

if (process.env.REACT_APP_ENV === 'production') {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID)
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
  })
}

function AppContainer() {
  const pathname = getRedirectPathIfAny(useLocation())
  if (pathname) {
    return <Redirect to={{ pathname }} replace />
  }
  return (
    <div className='App' id='App'>
      <BrowserRouter>
        <Switch>
          <Route path='/orders/:id' component={OrderContainerWrapped} />
          <Route path='/purchase/quotes/:id' component={PurchaseFromQuoteContainerWrapped} />
          <Route path='/purchase' component={PurchaseContainer} />
          <Route path='/quote' component={QuoteContainer} />
          <Route path='/' component={HomeContainer} />
        </Switch>
      </BrowserRouter>
    </div>
  )
}

function getRedirectPathIfAny({ search }) {
  const searchParams = new URLSearchParams(search)
  const orderId = searchParams.get('orderId')
  if (orderId) {
    const token = searchParams.get('token')
    return `/orders/${orderId}?token=${token}`
  }
}

function OrderContainerWrapped() {
  const params = useParams()
  return <OrderContainer id={params.id} />
}

function PurchaseFromQuoteContainerWrapped() {
  const params = useParams()
  return <PurchaseFromQuoteContainer id={params.id} />
}

export default AppContainer
