import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { getPriceTierRows } from '../../utils/discountUtil'

export default function PriceTierTable({ discountRates }) {
  const rows = getPriceTierRows(discountRates)

  return (
    <TableContainer component={Paper} className='priceTierTable'>
      <Table aria-label='price tier table'>
        <TableHead className='priceTierTable-header'>
          <TableRow>
            <TableCell align='center'>Number of teachers</TableCell>
            <TableCell align='center'>Discount %</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className='priceTierTable-body'>
          {rows.map((row) => (
            <TableRow key={row.seatCount}>
              <TableCell align='center'>{row.seatCount}</TableCell>
              <TableCell align='center'>{`${row.discountPercent}%`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
