import React, { Component } from 'react'
import Icon from './Icons'
import moment from 'moment'
import ProfilePic from './ProfilePic'


function formatTimestamp(timestamp) {
	const nowDate =moment()
	const today = moment(nowDate).startOf('day')
	const yest = moment().startOf('day').subtract(1,'day')
	let formatedTimestamp
	if (moment(timestamp).isSame(today, 'd')) {// Today 10:53 AM
		formatedTimestamp = `Today ${moment(timestamp).format('h:mm A')}`
	}else if(moment(timestamp).isSame(yest, 'd')) {// Yesterday 10:53 AM
		formatedTimestamp =`Yesterday ${moment(timestamp).format('h:mm A')}`
	}else {// November 11, 2021
		return moment(timestamp).format('MMMM DD, YYYY')
	}
	return formatedTimestamp
}

class OrderPageSeatsTableColHeader extends Component {
	render() {
		const {label, name, sortActive, sortAscending,changePassSortOrder} = this.props
		let newSortOrder='ascending'
		if(sortActive && sortAscending){
			newSortOrder='descending'
		}
		return (	
			<div className={'orderPage-seatsTable-colHeader '
				+ (name ? ` orderPage-seatsTable-colHeader--${name}` : '')
				+ (sortActive ? ' orderPage-seatsTable-colHeader--active' : ' orderPage-seatsTable-colHeader--inactive')
				}
				onClick={()=>{changePassSortOrder(name,newSortOrder)}}
			>
				<div className='orderPage-seatsTable-colHeader-label'>
					{label}
				</div>
				<div className='orderPage-seatsTable-colHeader-icon'>
					{sortActive && sortAscending &&
						<Icon name='chevron-small-up'/>
					}
					{sortActive && !sortAscending &&
						<Icon name='chevron-small-down'/>
					}
				</div>					
				
			</div>				
		)
	}
}

class OrderPageSeatsTableSeatRow extends Component {

	render() {
		const {pass}=this.props
		const {user}=pass
		return (		
			<div className='orderPage-seatsTable-seatRow'>
				<div className='orderPage-seatsTable-seatRowBorder'/>
				<div className='orderPage-seatsTable-seatRow-profilePicContainer'>
					<div className='orderPage-seatsTable-seatRow-profilePic'>
						<ProfilePic size={32} borderRadius={5} user={user} />
					</div>		
				</div>				
				<div className='orderPage-seatsTable-seatRow-firstNameContainer'>
					<div className='orderPage-seatsTable-seatRow-firstName'>
						{user.firstName}
					</div>
				</div>
				<div className='orderPage-seatsTable-seatRow-lastNameContainer'>
					<div className='orderPage-seatsTable-seatRow-lastName'>
						{user.lastName}
					</div>
				</div>
				<div className='orderPage-seatsTable-seatRow-emailContainer'>
					<div className='orderPage-seatsTable-seatRow-email'>
						{user.email}
					</div>
				</div>
				<div className='orderPage-seatsTable-seatRow-statusContainer'>
					<div className='orderPage-seatsTable-seatRow-statusBadge'>
						Active
					</div>
				</div>
				<div className='orderPage-seatsTable-seatRow-claimedContainer'>
					<div className='orderPage-seatsTable-seatRow-claimed'>
						{formatTimestamp(pass.claimedAt)}
					</div>
				</div>				
				<div className='orderPage-seatsTable-seatRow-removeBtn' onClick={()=>{this.props.confirmRemoveUser(pass)}}>
					Remove
				</div>
			</div>					
		)
	}
}



class OrderPageSeatsTable extends Component {

	render() {	
		const {order,passes,sortOrder,sortedBy,changePassSortOrder}=this.props
		const activeSeatCount = passes.length
		const totalSeatCount = order.passQty		
		return (	
			<div className='orderPage-seatsTable'>
				<div className='orderPage-seatsTable-title'>
					Claimed Memberships
				</div>
				<div className='orderPage-seatsTable-subtitle'>
					Using <b>{activeSeatCount}</b> of {totalSeatCount} memberships
				</div>
				{activeSeatCount !== 0 &&
					<React.Fragment>							
						<div className='orderPage-seatsTable-utilizationBar'>
							<div style={{width: `${activeSeatCount / totalSeatCount * 100}%` }} className='orderPage-seatsTable-utilizationBar-bar' />
							<div className='orderPage-seatsTable-utilizationBar-track' />
						</div>

						<div className='orderPage-seatsTable-colHeaders'>
							<div className='orderPage-seatsTable-colHeader orderPage-seatsTable-colHeader--profilePic' />
								<OrderPageSeatsTableColHeader 						
									label='First Name'						
									name='firstName'
									sortActive={sortedBy==='firstName'}
									sortAscending={sortOrder==='ascending'}
									changePassSortOrder={changePassSortOrder}
								/>
								<OrderPageSeatsTableColHeader 						
									label='Last Name'						
									name='lastName'
									sortActive={sortedBy==='lastName'}
									sortAscending={sortOrder==='ascending'}
									changePassSortOrder={changePassSortOrder}
								/>
								<OrderPageSeatsTableColHeader 
									label='Email'						
									name='email'
									sortActive={sortedBy==='email'}
									sortAscending={sortOrder==='ascending'}
									changePassSortOrder={changePassSortOrder}
								/>
								<OrderPageSeatsTableColHeader 
									label='Status'						
									name='status'
									sortActive={sortedBy==='status'}
									sortAscending={sortOrder==='ascending'}
									changePassSortOrder={changePassSortOrder}
								/>
								<OrderPageSeatsTableColHeader 
									label='Claimed'						
									name='claimedDate'
									sortActive={sortedBy==='claimedDate'}
									sortAscending={sortOrder==='ascending'}
									changePassSortOrder={changePassSortOrder}
								/>
						</div>
						<div className='orderPage-seatsTable-table'>	
							{passes.map((pass)=>{
								return(
									<OrderPageSeatsTableSeatRow
										key={pass.id}
										pass={pass}
										confirmRemoveUser={this.props.confirmRemoveUser}
									/>
								)
							})}				
						</div>
					</React.Fragment>
				}				
			</div>					
		)
	}
}

export default OrderPageSeatsTable

