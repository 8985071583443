import React, { Component } from 'react'
import Icon from './Icons'

class OrderPageInfo extends Component {

	render() {	
		
		return (	
		
			<div className='orderPage-info'>	
				<div className='orderPage-info-iconContainer'>
					<Icon name='information' />
				</div>

				{this.props.isActive &&					
					<div className='orderPage-info-h1'>						
						Teachers can claim their memberships by using the Claim Membership Link.
					</div>					
				}

				{!this.props.isActive &&
					<React.Fragment>						
						<div className='orderPage-info-h1'>						
							Your order will activate when the first membership is claimed. It will be valid for 1 year from activation.
						</div>
						<div className='orderPage-info-h1'>
							Teachers can claim their memberships by using the Claim Membership Link.
						</div>
						<div className='orderPage-info-distributeWarning'>							
							Don’t distribute the Claim Membership Link or License Key until you are ready to activate your order.
						</div>
					</React.Fragment>
				}

			</div>
		)
	}
}

export default OrderPageInfo

