import React from 'react'
import {Image} from 'cloudinary-react'
 
class ProfilePic extends React.Component{

  render(){  
    const {size, user} = this.props
    const defaultSize = 256
    let firstInitial = ''
    let lastInitial = ''
    let userImage = ''
    let userImageBgColor = ''
    if(user){
      if(user.firstName){
       firstInitial = user.firstName.charAt(0)
      }      
      if(user.lastName){
       lastInitial = user.lastName.charAt(0)
      }
      userImage = user.image
      userImageBgColor = user.imageBgColor
    }
    const scale = size / defaultSize
    return(
      <div style={{width: `${size}px`, height: `${size}px`, borderRadius: `${this.props.borderRadius}px`}} className={'profilePicContainer ' + (user && userImage ? ' profilePicContainer--hasImage' : '')}>
         {user && userImage &&
            <React.Fragment>
              <div className='profilePicContainer--hasImage-imgBorder' style={{borderRadius: `${this.props.borderRadius}px`}}/>                          
              <Image            
                cloudName={process.env.REACT_APP_CLOUDINARY_CLOUDNAME}
                publicId={`${user.image}/profile/${user.id}`}
                style={{width: `${size}px`, height: `${size}px`}} 
                className='profilePic profilePic--image'
                width='128'
                height='128'
                crop='fill'
              />
            </React.Fragment>
         }
         {user && !userImage &&
           <div style={{width: `${defaultSize}px`, height: `${defaultSize}px`, transform: `scale(${scale})`}} className={'profilePic profilePic--fallback' + (userImageBgColor ? ` profilePic--fallback--${userImageBgColor}` : '')}>
              <div className='profilePic--fallback-initialsContainer'>
                <div className='profilePic--fallback-spacer' />
                <div className='profilePic--fallback-spacer' />                
                <div className='profilePic--fallback-initial'>{firstInitial}</div>
                {lastInitial &&
                  <React.Fragment>
                    <div className='profilePic--fallback-spacer' />
                    <div className='profilePic--fallback-initial'>{lastInitial}</div>
                  </React.Fragment>
                }
                <div className='profilePic--fallback-spacer' />
                <div className='profilePic--fallback-spacer' />
              </div>
           </div>
         }
      </div>      
    )
  }
}

export default ProfilePic
